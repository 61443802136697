// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import overviewEN from './locales/en/overview.json'
import overviewES from './locales/es/overview.json'
import generalEN from './locales/en/general_settings.json'
import generalES from './locales/es/general_settings.json'
import sidebarEN from './locales/en/sidebar.json'
import sidebarES from './locales/es/sidebar.json'
import myProfileEN from './locales/en/myprofile.json'
import myProfileES from './locales/es/myprofile.json'
import socialLoginEN from './locales/en/socialLogin.json'
import socialLoginES from './locales/es/socialLogin.json'
import listingsEN from './locales/en/listings.json'
import listingsES from './locales/es/listings.json'
import postsEN from './locales/en/posts.json'
import postsES from './locales/es/posts.json'
import questionsEN from './locales/en/questions.json'
import questionsES from './locales/es/questions.json'
import homeEN from './locales/en/home.json'
import homeES from './locales/es/home.json'
import contactsEN from './locales/en/contacts_hub.json'
import contactsES from './locales/es/contacts_hub.json'
import sitesEN from './locales/en/sites.json'
import sitesES from './locales/es/sites.json'
import estimatesEN from './locales/en/estimates.json'
import estimatesES from './locales/es/estimates.json'
import paymentsEN from './locales/en/payments.json'
import paymentsES from './locales/es/payments.json'
import reviewsEN from './locales/en/reviews.json'
import reviewsES from './locales/es/reviews.json'
import commonEN from './locales/en/common.json'
import commonES from './locales/es/common.json'
import bookingsEN from './locales/en/bookings.json'
import bookingsES from './locales/es/bookings.json'
import activitiesEN from './locales/en/activities.json'
import activitiesES from './locales/es/activities.json'
import settingsEN from './locales/en/settings.json'
import settingsES from './locales/es/settings.json'
import servicesEN from './locales/en/services.json'
import servicesES from './locales/es/services.json'
import messengerEN from './locales/en/messenger.json'
import messengerES from './locales/es/messenger.json'
import locationsEN from './locales/en/locations.json'
import locationsES from './locales/es/locations.json'

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    locales: ['en', 'es'],
    debug: true,
    defaultNS: 'common',
    ns: [
      'common',
      'overview',
      'general_settings',
      'sidebar',
      'myprofile',
      'socialLogin',
      'listings',
      'posts',
      'questions',
      'home',
      'contacts_hub',
      'estimates',
      'payments',
      'reviews',
      'bookings',
      'activities',
      'settings',
      'services',
      'messenger',
      'locations'
    ],
    resources: {},
    nsSeparator: '.',
    keySeparator: false,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      wait: true
    },
    initImmediate: false
  })

i18n.addResourceBundle('en', 'overview', overviewEN)
i18n.addResourceBundle('es', 'overview', overviewES)
i18n.addResourceBundle('en', 'general_settings', generalEN)
i18n.addResourceBundle('es', 'general_settings', generalES)
i18n.addResourceBundle('en', 'sidebar', sidebarEN)
i18n.addResourceBundle('es', 'sidebar', sidebarES)
i18n.addResourceBundle('en', 'myprofile', myProfileEN)
i18n.addResourceBundle('es', 'myprofile', myProfileES)
i18n.addResourceBundle('en', 'socialLogin', socialLoginEN)
i18n.addResourceBundle('es', 'socialLogin', socialLoginES)
i18n.addResourceBundle('en', 'listings', listingsEN)
i18n.addResourceBundle('es', 'listings', listingsES)
i18n.addResourceBundle('en', 'posts', postsEN)
i18n.addResourceBundle('es', 'posts', postsES)
i18n.addResourceBundle('en', 'questions', questionsEN)
i18n.addResourceBundle('es', 'questions', questionsES)
i18n.addResourceBundle('en', 'home', homeEN)
i18n.addResourceBundle('es', 'home', homeES)
i18n.addResourceBundle('en', 'contacts_hub', contactsEN)
i18n.addResourceBundle('es', 'contacts_hub', contactsES)
i18n.addResourceBundle('en', 'sites', sitesEN)
i18n.addResourceBundle('es', 'sites', sitesES)
i18n.addResourceBundle('en', 'estimates', estimatesEN)
i18n.addResourceBundle('es', 'estimates', estimatesES)
i18n.addResourceBundle('en', 'payments', paymentsEN)
i18n.addResourceBundle('es', 'payments', paymentsES)
i18n.addResourceBundle('en', 'reviews', reviewsEN)
i18n.addResourceBundle('es', 'reviews', reviewsES)
i18n.addResourceBundle('en', 'common', commonEN)
i18n.addResourceBundle('es', 'common', commonES)
i18n.addResourceBundle('en', 'bookings', bookingsEN)
i18n.addResourceBundle('es', 'bookings', bookingsES)
i18n.addResourceBundle('en', 'activities', activitiesEN)
i18n.addResourceBundle('es', 'activities', activitiesES)
i18n.addResourceBundle('en', 'settings', settingsEN)
i18n.addResourceBundle('es', 'settings', settingsES)
i18n.addResourceBundle('en', 'services', servicesEN)
i18n.addResourceBundle('es', 'services', servicesES)
i18n.addResourceBundle('en', 'messenger', messengerEN)
i18n.addResourceBundle('es', 'messenger', messengerES)
i18n.addResourceBundle('en', 'locations', locationsEN)
i18n.addResourceBundle('es', 'locations', locationsES)

export default i18n
